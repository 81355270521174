import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import * as Dialog from '@radix-ui/react-dialog';

import CloseIcon from '@assets/icons/Close.svg';

import * as styles from './DialogPanel.module.css';

/**
 * This component only provides an abstraction over the styling of a dialog
 * window. The interactivity is still provieded by radix-ui, so you'll always
 * need to use DialogPanel withing <Dialog.Root> or radix.
 *
 * See: https://www.radix-ui.com/docs/primitives/components/dialog
 */
const DialogPanel = ({ title, description, children, className }) => {
  return (
    <Dialog.Portal>
      <Dialog.Overlay className={styles.overlay}>
        <Dialog.Content className={cn(styles.content, className)}>
          <Dialog.Title className={styles.title}>{title}</Dialog.Title>
          {description && (
            <Dialog.Description className={styles.description}>
              {description}
            </Dialog.Description>
          )}

          {children}

          <Dialog.Close asChild>
            <button className={styles.closeButton} aria-label="Close overlay">
              <CloseIcon className={styles.closeIcon} />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Overlay>
    </Dialog.Portal>
  );
};

DialogPanel.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default DialogPanel;
