import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ArrowLink from '@components/ArrowLink';
import Button from '@components/Button';
import interactionHooks from '@util/interactions';

import { dialogMap } from '@dialogs';

import * as styles from './Actions.module.css';

const Action = ({
  label,
  type,
  href,
  dialog,
  interaction: _interaction,
  trackingScope,
}) => {
  const interaction = _interaction ? interactionHooks[_interaction]() : null;

  const Dialog = dialog ? dialogMap[dialog] : null;

  const props = {
    href: Dialog ? null : href,
    variant: type ?? 'primary',
    onClick: Dialog ? null : interaction,
  };

  const element =
    type === 'button' ? (
      <Button
        href={props.href}
        onClick={props.onClick}
        trackingScope={trackingScope}
      >
        {label}
      </Button>
    ) : (
      <ArrowLink
        text={label}
        variant={props.type}
        href={props.href}
        onClick={props.onClick}
        trackingScope={trackingScope}
      />
    );

  return Dialog ? <Dialog>{element}</Dialog> : element;
};

const Actions = ({ actions, className, trackingScope }) => {
  return (
    <div className={cn(styles.root, className)}>
      {actions.map((action, index) => (
        <Action
          key={index}
          label={action.label}
          type={action.type}
          href={action.href}
          dialog={action.dialog}
          interaction={action.interaction}
          trackingScope={trackingScope}
        />
      ))}
    </div>
  );
};

Actions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['primary', 'secondary', 'default', 'button']),
      href: PropTypes.string,
      interaction: PropTypes.string,
    }),
  ).isRequired,
  className: PropTypes.string,
  trackingScope: PropTypes.string,
};

export default Actions;
