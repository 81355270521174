export const zeroPad = (num, places) => String(num).padStart(places, '0');
export const getDigits = (num) => String(num).length;

export const formatNumber = (num, places = 2) => {
  return Number(num)
    .toFixed(places)
    .replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
};

export const shortenAndFormatCurrency = (num, prefix = '$') => {
  if (num > 1000000000) {
    return formatCurrency(num / 1000000000, 0, prefix) + 'B';
  } else if (num > 1000000) {
    return formatCurrency(num / 1000000, 0, prefix) + 'M';
  } else if (num > 1000) {
    return formatCurrency(num / 1000, 0, prefix) + 'K';
  } else {
    return formatCurrency(num, 0, prefix);
  }
};

export const formatWithPrefix =
  (places = 2, prefix) =>
  (num) => {
    const _prefix = prefix ? `${prefix}\u2009` : '';
    return `${_prefix}${formatNumber(num, places)}`;
  };

export const formatCurrency = formatWithPrefix(2, '$');
export const formatCurrencyWithoutDecimals = formatWithPrefix(0, '$');

export const roundWithPrecision = (num, precision = 2) => {
  if (num == null) return null;

  const power = Math.pow(10, precision);
  return Math.round((parseFloat(num) + Number.EPSILON) * power) / power;
};

export const maybeNumber = (num) => {
  if (num === null || num === undefined || num === '') return null;
  return parseFloat(num);
};
