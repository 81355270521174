import cn from 'classnames';

import { colorMap } from '@constants/colors';

import Actions from '@components/Actions';
import TickList from '@components/TickList';
import TextRenderer from '@components/TextRenderer';

import flowStyles from '@styles/utilities/flow.module.css';
import measureStyles from '@styles/utilities/measures.module.css';
import themeStyles from '@styles/themes.module.css';
import textStyles from '@styles/textStyles.module.css';
import * as styles from './PageSection.module.css';

export const DEFAULT_TITLE_TAG = 'h2';

const PageSection = ({
  children,
  className,
  blockSpacing = 'medium',
  theme = null,
  gradient = null,
  markerColor = null,
  layout = 'vertical',
  contentWidth = 'default',
  eyebrow,
  title,
  titleTextSize = 'medium',
  description,
  descriptionTextSize = 'medium',
  listItems,
  withBorder = false,
  sticky = true,
  headingTag = DEFAULT_TITLE_TAG,
  actions,
  anchorId,
  trackingScope = '',
}) => {
  const cssClasses = cn(
    styles.root,
    {
      [themeStyles.light]: theme === 'light',
      [themeStyles.dark]: theme === 'dark',
      [styles.hasTheme]: theme !== null,
      [styles.hasGradient]: gradient !== null && theme !== null,
      [styles.verticalLayout]: layout === 'vertical',
      [styles.horizontalLayout]: layout === 'horizontal',
      [styles.contentWidthWide]: contentWidth === 'wide',
      [styles.verticalLayout]: layout === 'vertical',
      [styles.hasMeta]: eyebrow || title || description || listItems,
      [styles.offsetForEyebrow]: eyebrow && (title || description),
      [styles.sticky]: sticky && layout === 'horizontal',
      [styles.withBorder]: withBorder,
    },
    className,
  );

  const headlineCssClasses = cn(
    {
      [textStyles.headlineS]: titleTextSize === 'small',
      [textStyles.headlineM]: titleTextSize === 'medium',
      [textStyles.headlineL]: titleTextSize === 'large',
      [textStyles.headlineXL]: titleTextSize === 'xlarge',
    },
    {
      [measureStyles.wide]: titleTextSize === 'small',
      [measureStyles.medium]: titleTextSize === 'medium',
      [measureStyles.narrow]: titleTextSize === 'large',
      [measureStyles.narrow]: titleTextSize === 'xlarge',
    },
    styles.title,
  );

  const descriptionCssClasses = cn(
    {
      [textStyles.bodyS]: descriptionTextSize === 'small',
      [textStyles.bodyM]: descriptionTextSize === 'medium',
      [textStyles.bodyL]: descriptionTextSize === 'large',
      [textStyles.bodyXL]: descriptionTextSize === 'xlarge',
    },
    styles.description,
  );

  const childrenCssClasses = cn(styles.content, {
    [flowStyles.root]: blockSpacing !== null,
    [flowStyles.small]: blockSpacing === 'small',
    [flowStyles.medium]: blockSpacing === 'medium',
    [flowStyles.large]: blockSpacing === 'large',
    [flowStyles.xlarge]: blockSpacing === 'xlarge',
  });

  const Heading = headingTag;
  const TitleTag = !eyebrow ? headingTag : 'p';

  const meta = (
    <>
      {eyebrow && <Heading className={textStyles.eyebrow}>{eyebrow}</Heading>}
      {title && (
        <TextRenderer
          text={title}
          as={TitleTag}
          className={headlineCssClasses}
        />
      )}
      {description && (
        <TextRenderer className={descriptionCssClasses} text={description} />
      )}
      {listItems && <TickList className={cn(styles.list)} items={listItems} />}
      {actions && (
        <Actions
          className={cn(styles.actions)}
          actions={actions}
          trackingScope={trackingScope}
        />
      )}
    </>
  );

  const sectionStyles = {
    '--page-section-gradient-color': gradient && colorMap[gradient],
    '--section-marker-color': markerColor && colorMap[markerColor],
  };

  return (
    <section className={cssClasses} style={sectionStyles} id={anchorId}>
      <div className={cn(styles.header)}>{meta}</div>
      <div className={childrenCssClasses}>{children}</div>
    </section>
  );
};

// We don't want a display name for this component, as it really is a HOC
// eslint-disable-next-line
export const withPageSection = (Component, overwrites) => (props) => {
  return (
    <PageSection {...props} {...overwrites}>
      <Component {...props} />
    </PageSection>
  );
};

export default PageSection;
