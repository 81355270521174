import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import useStateMachine from '@cassiozen/usestatemachine';
import { useForm } from 'react-hook-form';
import * as Dialog from '@radix-ui/react-dialog';

import { trackAnalyticsEvent } from '@util/analytics';

import Link from '@components/Link';
import Button from '@components/Button';
import Icon from '@components/Icon';
import { TextInput } from '@components/Inputs';
import DialogPanel from '@components/DialogPanel';

import feedbackFormContent from '@content/feedbackForm.json';
import siteInformation from '@content/default.json';

import { submitRoute } from '@util/routes';

import * as styles from './FeedbackForm.module.css';
import * as themeStyles from '@styles/themes.module.css';

const submitFeedbackForm = async (data, { reject, resolve }) => {
  try {
    const res = await fetch(submitRoute(), {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        formName: 'navigatorFeedback',
        ...data,
      }),
    });

    if (res && res.ok) {
      resolve();
    } else {
      reject();
    }
  } catch {
    reject();
  }
};

const Inner = ({ registry = '', name = '', saveData }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    values: {
      registry,
      name,
    },
  });

  const {
    feedbackForm: { intro, fields, error, success },
  } = feedbackFormContent;

  const [formState, send] = useStateMachine({
    initial: 'idle',
    states: {
      idle: {
        on: { SUBMIT: 'sending' },
      },
      sending: {
        on: { FAIL: 'error', DONE: 'success' },
        effect({ event, send }) {
          saveData(event.data, {
            resolve: () => send('DONE'),
            reject: () => send('FAIL'),
          });
        },
      },
      error: {
        effect() {
          trackAnalyticsEvent('feedbackSubmissionError');
        },
      },
      success: {
        effect() {
          trackAnalyticsEvent('feedbackSubmissionSuccess');
        },
      },
    },
  });

  const isSending = useMemo(() => formState.value === 'sending', [formState]);
  const showSuccess = useMemo(() => formState.value === 'success', [formState]);
  const showError = useMemo(() => formState.value === 'error', [formState]);

  return (
    <DialogPanel title="Submit Feedback" className={themeStyles.purple}>
      <div className={styles.root}>
        <form
          onSubmit={handleSubmit((data) => {
            send({ type: 'SUBMIT', data });
          })}
        >
          <input type="hidden" {...register('registry')} />
          <input type="hidden" {...register('name')} />

          <div className={styles.intro}>{intro}</div>

          {registry !== '' && name !== '' && (
            <div className={styles.summary}>
              <div className={styles.summaryItem}>
                <div className={styles.summaryLabel}>Package</div>
                <div className={styles.summaryValue}>{name}</div>
              </div>
              <div className={styles.summaryItem}>
                <div className={styles.summaryLabel}>Registry</div>
                <div className={styles.summaryValue}>{registry}</div>
              </div>
            </div>
          )}

          <div className={cn(styles.inputs)}>
            <TextInput
              label={fields.email.label}
              placeholder={fields.email.placeholder}
              errors={errors.email}
              {...register('email', {
                required: fields.email.validation.required,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: fields.email.validation.valid,
                },
              })}
            />

            <TextInput
              multiline
              label={fields.message.label}
              placeholder={fields.message.placeholder}
              errors={errors.message}
              rows={4}
              {...register('message', {
                required: fields.message.validation.required,
              })}
            />
          </div>

          <div className={cn(styles.actions)}>
            <Button
              disabled={isSending}
              type="submit"
              trackingScope="contactSales"
            >
              {isSending ? 'Sending' : fields.submit.label}
            </Button>
          </div>
        </form>
        {showError && (
          <div role="alert" className={cn(styles.overlay)}>
            <div className={cn(styles.overlayText)}>
              <p>{error.title}</p>
              <p>
                {error.fallback}{' '}
                <Link
                  href={`mailto: ${siteInformation.navigatorEmail}`}
                  className={cn(styles.fallbackLink)}
                >
                  {siteInformation.navigatorEmail}
                </Link>
              </p>
            </div>
          </div>
        )}
        {showSuccess && (
          <div className={cn(styles.overlay)}>
            <Icon icon="success" className={cn(styles.overlayIcon)} />
            <div className={cn(styles.overlayText)}>
              {success.title} <span>{success.subline}</span>
            </div>
          </div>
        )}
      </div>
    </DialogPanel>
  );
};

const FeedbackForm = ({
  registry,
  name,
  saveData = submitFeedbackForm,
  children,
}) => {
  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Inner registry={registry} name={name} saveData={saveData} />
      </Dialog.Portal>
    </Dialog.Root>
  );
};

FeedbackForm.propTypes = {
  props: PropTypes.object,
};

export default FeedbackForm;
